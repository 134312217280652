import React, { createContext, useEffect, useState } from 'react';
import { ApiError, OptionalApiError } from 'lib/error';

export type ErrorContextProps = {
  error: OptionalApiError;
  errors: ApiError[];
  setError: (e: OptionalApiError) => void;
};

export const ErrorContext = createContext({} as ErrorContextProps);

export const ErrorProvider = ({ children }: { children: any }) => {
  const [error, setError] = useState<OptionalApiError>(null);
  const [errors, setErrors] = useState<ApiError[] | []>([]);

  useEffect(() => {
    if (error) setErrors([...errors, error]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <ErrorContext.Provider value={{ error, errors, setError }}>
      {children}
    </ErrorContext.Provider>
  );
};
