import config from 'config.json';
import {
  ConsoleReporter,
  createUniversalReporter,
  createSemanticMetrics,
  EventSenderReporter,
  Metric,
} from '@spotify-internal/semantic-metrics';
import { getTransportInstance } from 'services/websiteTransport';
import { Transport } from '@spotify-internal/transport';
export {
  MetricTypes,
  BrowserMetrics,
  Timer,
} from '@spotify-internal/semantic-metrics';
export type { Nanoseconds } from '@spotify-internal/semantic-metrics';

const { clientId } = config;

export const { sendMetric: sendMetricNonAuth } = createSemanticMetrics(
  process.env.NODE_ENV === 'development'
    ? { key: clientId, reporter: new ConsoleReporter() }
    : { key: clientId, reporter: createUniversalReporter() },
);

export const sendMetricAuth = (metric: Metric) =>
  new Promise<void>((resolve, reject) =>
    getTransportInstance().then((transport: Transport) => {
      const reporter = EventSenderReporter.createWithCustomTransport({
        environment: 'browser',
        transport,
      });
      reporter
        .send([{ key: clientId, ...metric }])
        .then(resolve)
        .catch(reject);
    }),
  );
