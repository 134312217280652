export function getFirstStringOrDefault(
  strings: string[] | string | undefined,
  defaultValue: string,
): string {
  switch (typeof strings) {
    case 'object':
      return strings[0];
    case 'string':
      return strings;
    default:
      return defaultValue;
  }
}

export function hashCode(string: string): number {
  let hash = 0;
  const length = string.length;
  let i = 0;
  if (length > 0)
    while (i < length) hash = ((hash << 5) - hash + string.charCodeAt(i++)) | 0;
  return hash;
}
