export type IframeHostEnvironment =
  | 'production'
  | 'testing'
  | 'development'
  | null;

/*
"e" query parameter is set when the page is hosted from an iframe. It can be set to:
  "t" - when hosted in the testing environment
  "p" - when hosted in the production environment
  "d" - when hosted locally
  it is unset when it's not rendered in an iframe
*/
export function getIframeHostEnvironmentFromQueryParam(
  envQueryParameter: string[] | string | undefined,
): IframeHostEnvironment {
  const firstEnvQueryParameter = envQueryParameter?.length
    ? envQueryParameter[0]
    : envQueryParameter;
  switch (firstEnvQueryParameter) {
    case 'p':
      return 'production';
    case 't':
      return 'testing';
    case 'd':
      return 'development';
    default:
      return null;
  }
}
