export type RuntimeEnvironment =
  | 'test'
  | 'development'
  | 'slingshot'
  | 'shared'
  | 'production';

export const getEnvironment = (): RuntimeEnvironment =>
  process.env.runtimeEnvironment
    ? (process.env.runtimeEnvironment as RuntimeEnvironment)
    : 'production';
