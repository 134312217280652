import {
  AxiosResponse,
  AxiosRequestConfig,
  AxiosInstance,
  AxiosError,
} from 'axios';
import { captureException } from '@sentry/nextjs';

export type CSRFClient = {
  getToken: () => string | undefined;
  fetchWithToken: (
    api: AxiosInstance,
    reqInit: AxiosRequestConfig,
  ) => Promise<AxiosResponse>;
};

export const CSRF_TOKEN_HEADER = 'x-csrf-token';
export const CSRF_VALID_HEADER = 'x-csrf-valid';
const DEFAULT_MAX_RETRIES = 1;

export const shouldRetryWithUpdatedToken = ({
  error,
}: {
  error: AxiosError;
}): boolean => {
  const responseToken = error.response?.headers[CSRF_TOKEN_HEADER];
  return !!(
    error.response?.headers[CSRF_VALID_HEADER] === 'false' && responseToken
  );
};

export const defaultRetryConditions = (
  failureCount: number,
  error: AxiosError,
) => {
  return (
    shouldRetryWithUpdatedToken({ error }) && failureCount < DEFAULT_MAX_RETRIES
  );
};

export const createCSRFClient = (initialToken?: string): CSRFClient => {
  let storedToken: string | undefined = initialToken;

  const csrfProtectedFetch = (
    api: AxiosInstance,
    reqInit: AxiosRequestConfig,
  ): Promise<AxiosResponse> => {
    if (!storedToken) {
      captureException(Error(`Missing CSRF Token, requestUri: ${reqInit.url}`));
      throw new Error('Missing CSRF token.');
    }

    reqInit.headers = {
      ...reqInit.headers,
      [CSRF_TOKEN_HEADER]: storedToken,
    };

    return new Promise<AxiosResponse>((resolve, reject) =>
      api(reqInit)
        .then(res => {
          const responseToken = res.headers[CSRF_TOKEN_HEADER];
          storedToken = responseToken || storedToken;
          resolve(res);
        })
        .catch((err: AxiosError) => {
          const responseToken = err.response?.headers[CSRF_TOKEN_HEADER];
          storedToken = responseToken || storedToken;
          reject(err);
        }),
    );
  };

  return {
    getToken: () => storedToken,
    fetchWithToken: (
      api: AxiosInstance,
      reqInit: AxiosRequestConfig,
    ): Promise<AxiosResponse> => {
      return csrfProtectedFetch(api, reqInit);
    },
  };
};
