import * as tokens from 'styles';

export type Theme = {
  background: string;
  invertedBackground: string;
  foreground: string;
  invertedForeground: string;
  main: string;
  hero: string;
  textColor: string;
  invertedTextColor: string;
  logoColor: string;
  invertedLogoColor: string;
  linkColor: string;
  bulletBackground: string;
  bulletColor: string;
  sectionBackground: string;
  sectionForeground: string;
};

// Family
export const FamilyTheme: Theme = {
  background: tokens.gray95,
  invertedBackground: tokens.gray7,
  foreground: tokens.white,
  invertedForeground: tokens.gray7,
  main: tokens.storm155,
  hero: tokens.gray7,
  textColor: tokens.gray10,
  invertedTextColor: tokens.white,
  logoColor: tokens.black,
  linkColor: tokens.royalBlue,
  bulletBackground: tokens.black,
  bulletColor: tokens.white,
  invertedLogoColor: tokens.white,
  sectionBackground: tokens.white,
  sectionForeground: tokens.black,
};

// Duo
export const DuoTheme: Theme = {
  background: tokens.gray95,
  invertedBackground: tokens.gray7,
  foreground: tokens.white,
  invertedForeground: tokens.gray7,
  main: tokens.sunflower,
  hero: tokens.gray7,
  textColor: tokens.black,
  invertedTextColor: tokens.white,
  logoColor: tokens.black,
  invertedLogoColor: tokens.white,
  linkColor: tokens.black,
  bulletBackground: tokens.black,
  bulletColor: tokens.white,
  sectionBackground: tokens.sunflower,
  sectionForeground: tokens.black,
};
