import { createContext } from 'react';
import { PlanType } from 'lib/plans';

export type PlanContextProps = {
  planType: PlanType;
};

export const PlanContext = createContext<PlanContextProps>(
  {} as PlanContextProps,
);

export const PlanProvider = PlanContext.Provider;
export const PlanConsumer = PlanContext.Consumer;
