import React from 'react';
import { createCSRFClient, CSRFClient } from 'lib/csrf';

export type CSRFContextProps = {
  csrfClient: CSRFClient;
};

export const csrfContextDefaultValues: CSRFContextProps = {
  csrfClient: createCSRFClient(),
};

const CSRFContext = React.createContext<CSRFContextProps>(
  csrfContextDefaultValues,
);

export default CSRFContext;
