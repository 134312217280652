import { getCLS, getFID, getLCP } from 'web-vitals';
import { getWebVitalsReporter } from 'lib/semanticMetrics';
import { Locale } from 'lib/i18n';

const TOKEN_PLACEHOLDER = '<token>';
const OFFER_PLACEHOLDER = '<offer>';

const segmentMap: Record<string, string> = {
  success: OFFER_PLACEHOLDER,
  invite: TOKEN_PLACEHOLDER,
  reverify: TOKEN_PLACEHOLDER,
  confirm: TOKEN_PLACEHOLDER,
  location: TOKEN_PLACEHOLDER,
  address: TOKEN_PLACEHOLDER,
};

// Removes the query params and hash targets from the path
const cleanPath = (fullPath: string): string =>
  (fullPath.split('?').shift() as string).split('#').shift() as string;

/**
 * @deprecated use NextRouter::pathname
 * @param path
 * @returns
 */
export const sanitizePath = (path = '') =>
  cleanPath(path)
    .split('/')
    .slice(2)
    .reduce<Array<string>>((acc, segment: string) => {
      const lastElement = acc[acc.length - 1];
      const newElement = segment ? segmentMap[lastElement] ?? segment : segment;
      return [...acc, newElement];
    }, [])
    .join('/');

const reportWebVitals = (locale: Locale, pathName: string) => {
  const getWebVitalsReporterWithLabel = getWebVitalsReporter(pathName, locale);

  getCLS(getWebVitalsReporterWithLabel('cumulative-layout-shift'));
  getFID(getWebVitalsReporterWithLabel('first-input-delay'));
  getLCP(getWebVitalsReporterWithLabel('largest-contentful-paint'));
};

export async function setupReporters({
  pathName,
  locale,
}: {
  pathName: string;
  locale: Locale;
}) {
  reportWebVitals(locale, pathName);
}
