import {
  createBaseTransport,
  Transport,
  TransportEvent,
} from '@spotify-internal/transport';
import {
  createProviders,
  createTokenProvider,
} from '@spotify-internal/transport/es5/providers/spotify_com';
import { requestTransformerCreator } from '@spotify-internal/transport/es5/plugins/request_transformer';

let transportResolution: Promise<Transport>;

const createDevProviders = () => ({
  endpoints: () =>
    Promise.resolve({
      webgate: '/api/',
      webapi: '/api/',
    }),
  token: createTokenProvider(),
});

export const getWebsiteTransport = () => {
  const transport = createBaseTransport({
    providers: (process.env.NODE_ENV === 'development'
      ? createDevProviders()
      : createProviders()) as any,
  });

  transport.addPlugin<any>(requestTransformerCreator, {
    optionsOverrides: {
      // Sets 'authorize' to false on all requests to omit the dummy token authorization header
      authorize: false,
    },
  });

  transport.once(TransportEvent.CONNECTED, () => {
    transport.authenticate();
  });
  return transport;
};

export const getTransportInstance = () => {
  if (!transportResolution) {
    transportResolution = new Promise(resolve => {
      const transport = getWebsiteTransport();

      transport.once(TransportEvent.AUTHENTICATED, () => {
        resolve(transport);
      });

      transport.connect();
    });
  }
  return transportResolution;
};

export { TransportEvent } from '@spotify-internal/transport';
