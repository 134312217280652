import { keyframes } from 'styled-components';
import {
  screenSmMin,
  screenMdMin,
  screenLgMin,
} from '@spotify-internal/encore-web';
export {
  black,
  factoryYellow,
  gray7,
  gray10,
  gray20,
  gray30,
  gray50,
  gray70,
  gray80,
  gray85,
  gray90,
  gray95,
  greenLight,
  royalBlue,
  spacer12,
  spacer16,
  spacer20,
  spacer24,
  spacer32,
  spacer4,
  spacer40,
  spacer48,
  spacer56,
  spacer64,
  spacer8,
  spacer80,
  sunflower,
  violet,
  white,
  green,
} from '@spotify-internal/encore-web';

// colors
export const royalBlueHighlight = '#5276e4';
export const royalBlueFocus = '#305cde';
export const nobelGray = '#9B9B9B';
export const vividViolet = '#773795';
export const somberViolet = '#84106e';
export const lightPink = '#DFA9D5';
export const failure = '#e22134';
export const success = '#1db954';
export const storm155 = '#a5bbd1';
export const backdropBackground = 'rgba(0, 0, 0, 0.3)';

// animations
export const easingAdvanceDefault = 'cubic-bezier(0.3, 0, 0, 1)';
export const easingGeneric = 'cubic-bezier(0.3, 0, 0.7, 1)';
export const easeInOutCubic = 'cubic-bezier(0.65, 0, 0.35, 1)';

export const buttonTransitionFade = '33ms';
export const buttonHoverScale = 1.04;

export const zoom = keyframes`
  from { transform: scale(0); }
  to { transform: scale(1); }
`;

export const fadeOutDown = keyframes`
  from { opacity: 1; }
  to { opacity: 0;  transform: translate3d(0, 15px, 0); }
`;

export const fadeInDelayed = (endOpacity = 1) => keyframes`
  0% { opacity: 0; }
  60% { opacity: 0; }
  100% { opacity: ${endOpacity}; }
`;

export const expandWidth = (start: number, stop: number) => keyframes`
  0% { width: ${start}%; }
  100% { width: ${stop}%; }
`;

export const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const slideInUp = keyframes`
  0% {
    transform: translate3d(0, 100%, 0);
    visibility: hidden;
  }

  60% {
    transform: translate3d(0, 100%, 0);
    visibility: hidden;
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
`;

export const bounceIn = keyframes`
  from, 60%, 80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% { opacity: 0; transform: translate3d(0, -15px, 0); }

  60% { opacity: 1; transform: translate3d(0, 3px, 0); }

  80% { transform: translate3d(0, -2px, 0); }

  to { transform: translate3d(0, 0, 0); }
`;

// export breakpoints' values with stripped units
export const breakpointValues = {
  screenSm: parseFloat(screenSmMin),
  screenMd: parseFloat(screenMdMin),
  screenLg: parseFloat(screenLgMin),
};

export const updateGlobalViewportHeightVar = () => {
  document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);
};
