export type Locale =
  | 'en'
  | 'ad'
  | 'ad-ca'
  | 'ae-ar'
  | 'ae-en'
  | 'ag'
  | 'al'
  | 'am'
  | 'ao-en'
  | 'ao-pt'
  | 'ar'
  | 'at'
  | 'au'
  | 'az-az'
  | 'az-en'
  | 'ba'
  | 'ba-bs'
  | 'bb'
  | 'bd-bn'
  | 'bd-en'
  | 'be-fr'
  | 'be-nl'
  | 'bf-en'
  | 'bf-fr'
  | 'bg-bg'
  | 'bg-en'
  | 'bh-ar'
  | 'bh-en'
  | 'bi-en'
  | 'bi-fr'
  | 'bj-en'
  | 'bj-fr'
  | 'bn-en'
  | 'bn-ms'
  | 'bo'
  | 'br-en'
  | 'br-pt'
  | 'bs'
  | 'bt'
  | 'bw'
  | 'by-en'
  | 'by-ru'
  | 'bz-en'
  | 'bz-es'
  | 'ca-en'
  | 'ca-fr'
  | 'cd-fr'
  | 'cd-sw'
  | 'cg-en'
  | 'cg-fr'
  | 'ch-de'
  | 'ch-fr'
  | 'ci-en'
  | 'ci-fr'
  | 'cl'
  | 'cm-en'
  | 'cm-fr'
  | 'co-en'
  | 'co-es'
  | 'cr'
  | 'cv-en'
  | 'cv-pt'
  | 'cw-en'
  | 'cw-nl'
  | 'cy'
  | 'cz'
  | 'de'
  | 'de-en'
  | 'dj-en'
  | 'dj-fr'
  | 'dk-da'
  | 'dk-en'
  | 'dm'
  | 'do'
  | 'dz-ar'
  | 'dz-fr'
  | 'ec'
  | 'ee-en'
  | 'ee-et'
  | 'eg-ar'
  | 'eg-en'
  | 'es'
  | 'es-ca'
  | 'es-eu'
  | 'es-gl'
  | 'et-am'
  | 'et-en'
  | 'fi'
  | 'fj'
  | 'fm'
  | 'fr'
  | 'ga-en'
  | 'ga-fr'
  | 'gd'
  | 'ge'
  | 'gh'
  | 'gm'
  | 'gn-en'
  | 'gn-fr'
  | 'gq-en'
  | 'gq-es'
  | 'gr'
  | 'gt'
  | 'gw-en'
  | 'gw-pt'
  | 'gy'
  | 'hk-en'
  | 'hk-zh'
  | 'hn'
  | 'hr-en'
  | 'hr-hr'
  | 'ht-en'
  | 'ht-fr'
  | 'hu'
  | 'id-en'
  | 'id-id'
  | 'ie'
  | 'il-en'
  | 'il-he'
  | 'in-en'
  | 'in-hi'
  | 'iq-ar'
  | 'iq-en'
  | 'is-en'
  | 'is-is'
  | 'it'
  | 'jm'
  | 'jo-ar'
  | 'jo-en'
  | 'jp'
  | 'ke-en'
  | 'ke-sw'
  | 'kg-en'
  | 'kg-ru'
  | 'kh'
  | 'ki'
  | 'km-en'
  | 'km-fr'
  | 'kn'
  | 'kr-en'
  | 'kr-ko'
  | 'kw-ar'
  | 'kw-en'
  | 'kz-en'
  | 'kz-ru'
  | 'la'
  | 'lb-ar'
  | 'lb-en'
  | 'lc'
  | 'li'
  | 'lk-en'
  | 'lk-ta'
  | 'lr'
  | 'ls'
  | 'lt-en'
  | 'lt-lt'
  | 'lu-de'
  | 'lu-fr'
  | 'lv-en'
  | 'lv-lv'
  | 'ly-ar'
  | 'ly-en'
  | 'ma-ar'
  | 'ma-fr'
  | 'mc'
  | 'md-en'
  | 'md-ru'
  | 'me-en'
  | 'me-sr'
  | 'mg-en'
  | 'mg-fr'
  | 'mh'
  | 'mk'
  | 'mk-mk'
  | 'ml-en'
  | 'ml-fr'
  | 'mn'
  | 'mo-en'
  | 'mo-zh'
  | 'mr-ar'
  | 'mr-en'
  | 'mt'
  | 'mu'
  | 'mv-ar'
  | 'mv-en'
  | 'mw'
  | 'mx'
  | 'mx-en'
  | 'my-en'
  | 'my-ms'
  | 'mz-en'
  | 'mz-pt'
  | 'na-af'
  | 'na-en'
  | 'ne-en'
  | 'ne-fr'
  | 'ng'
  | 'ni'
  | 'nl'
  | 'nl-en'
  | 'no-en'
  | 'no-nb'
  | 'np-en'
  | 'np-ne'
  | 'nr'
  | 'nz'
  | 'om-ar'
  | 'om-en'
  | 'pa'
  | 'pe'
  | 'pg'
  | 'ph-en'
  | 'ph-tl'
  | 'pk-en'
  | 'pk-ur'
  | 'pl'
  | 'ps-ar'
  | 'ps-en'
  | 'pt-en'
  | 'pt-pt'
  | 'pw'
  | 'py'
  | 'qa-ar'
  | 'qa-en'
  | 'ro-en'
  | 'ro-ro'
  | 'rs-en'
  | 'rs-sr'
  | 'rw'
  | 'sa-ar'
  | 'sa-en'
  | 'sb'
  | 'sc-en'
  | 'sc-fr'
  | 'se'
  | 'sg-en'
  | 'sg-zh'
  | 'si-en'
  | 'si-sl'
  | 'sk-en'
  | 'sk-sk'
  | 'sl'
  | 'sm-en'
  | 'sm-it'
  | 'sn-en'
  | 'sn-fr'
  | 'sr-en'
  | 'sr-nl'
  | 'st-en'
  | 'st-pt'
  | 'sv'
  | 'sz'
  | 'td-en'
  | 'td-fr'
  | 'tg-en'
  | 'tg-fr'
  | 'th-en'
  | 'th-th'
  | 'tj-en'
  | 'tj-ru'
  | 'tl-en'
  | 'tl-pt'
  | 'tn-ar'
  | 'tn-fr'
  | 'to'
  | 'tr-en'
  | 'tr-tr'
  | 'tt'
  | 'tv'
  | 'tw'
  | 'tz-en'
  | 'tz-sw'
  | 'ua-en'
  | 'ua-uk'
  | 'ug-en'
  | 'ug-sw'
  | 'uk'
  | 'us'
  | 'uy'
  | 'uz'
  | 'vc'
  | 've-en'
  | 've-es'
  | 'vn-en'
  | 'vn-vi'
  | 'vu-en'
  | 'vu-fr'
  | 'ws'
  | 'xk-en'
  | 'xk-sr'
  | 'za-en'
  | 'za-zu'
  | 'zm'
  | 'zw';
export type LanguageCode =
  | 'en'
  | 'es_AD'
  | 'es_ES'
  | 'es'
  | 'ca_AD'
  | 'ca_ES'
  | 'ca'
  | 'ar_AE'
  | 'ar'
  | 'en_AE'
  | 'en_AG'
  | 'en_AL'
  | 'en_AM'
  | 'en_AO'
  | 'pt_AO'
  | 'pt'
  | 'pt_PT'
  | 'es_LA'
  | 'es_419'
  | 'de_AT'
  | 'de_DE'
  | 'de'
  | 'en_AU'
  | 'az_AZ'
  | 'az'
  | 'en_AZ'
  | 'en_BA'
  | 'bs_BA'
  | 'bs'
  | 'en_BB'
  | 'bn_BD'
  | 'bn'
  | 'bn_IN'
  | 'en_BD'
  | 'fr_BE'
  | 'fr_FR'
  | 'fr'
  | 'nl_BE'
  | 'nl_NL'
  | 'nl'
  | 'en_BF'
  | 'fr_BF'
  | 'bg_BG'
  | 'bg'
  | 'en_BG'
  | 'ar_BH'
  | 'en_BH'
  | 'en_BI'
  | 'fr_BI'
  | 'en_BJ'
  | 'en_BW'
  | 'ms_BW'
  | 'ms_MY'
  | 'ms'
  | 'es_BO'
  | 'en_BR'
  | 'pt_BR'
  | 'en_BS'
  | 'en_BT'
  | 'en_BY'
  | 'ru_BY'
  | 'ru_RU'
  | 'ru'
  | 'en_BZ'
  | 'es_BZ'
  | 'en_CA'
  | 'fr_CA'
  | 'fr_CD'
  | 'sw_KE'
  | 'sw'
  | 'en_CG'
  | 'fr_CG'
  | 'de_CH'
  | 'fr_CH'
  | 'en_CI'
  | 'fr_CI'
  | 'es_CL'
  | 'en_CM'
  | 'fr_CM'
  | 'en_CO'
  | 'es_CO'
  | 'es_CR'
  | 'en_CV'
  | 'pt_CV'
  | 'en_CW'
  | 'nl_CW'
  | 'en_CY'
  | 'cs_CZ'
  | 'en_GB'
  | 'en_DJ'
  | 'fr_DJ'
  | 'da_DK'
  | 'da'
  | 'en_DK'
  | 'en_DM'
  | 'es_DO'
  | 'ar_DZ'
  | 'fr_DZ'
  | 'es_EC'
  | 'en_EE'
  | 'et_EE'
  | 'et'
  | 'ar_EG'
  | 'en_EG'
  | 'eu_ES'
  | 'eu'
  | 'gl_ES'
  | 'gl'
  | 'am_ET'
  | 'am'
  | 'en_ET'
  | 'fi_FI'
  | 'fi'
  | 'en_FJ'
  | 'en_FM'
  | 'en_GA'
  | 'fr_GA'
  | 'en_GD'
  | 'en_GH'
  | 'en_GM'
  | 'en_GN'
  | 'fr_GN'
  | 'en_GQ'
  | 'es_GQ'
  | 'el_GR'
  | 'el'
  | 'es_GT'
  | 'en_GW'
  | 'pt_GW'
  | 'en_GY'
  | 'en_HK'
  | 'zh_HANT'
  | 'zh_TW'
  | 'es_HN'
  | 'en_HR'
  | 'hr_HR'
  | 'hr'
  | 'en_HT'
  | 'fr_HT'
  | 'hu_HU'
  | 'hu'
  | 'en_ID'
  | 'id_ID'
  | 'id'
  | 'en_IE'
  | 'en_IL'
  | 'he_IL'
  | 'he'
  | 'en_IN'
  | 'hi_IN'
  | 'hi'
  | 'ar_IQ'
  | 'en_IQ'
  | 'en_IS'
  | 'is_IS'
  | 'is'
  | 'it_IT'
  | 'it'
  | 'en_JM'
  | 'ar_JO'
  | 'en_JO'
  | 'ja_JP'
  | 'ja'
  | 'en_KE'
  | 'en_KG'
  | 'ru_KG'
  | 'en_KH'
  | 'en_KI'
  | 'en_KM'
  | 'fr_KM'
  | 'en_KN'
  | 'en_KR'
  | 'ko_KR'
  | 'ko'
  | 'ar_KW'
  | 'en_KW'
  | 'en_KZ'
  | 'ru_KZ'
  | 'en_LA'
  | 'ar_LB'
  | 'en_LB'
  | 'en_LC'
  | 'de_LI'
  | 'en_LK'
  | 'ta_LK'
  | 'ta_IN'
  | 'ta'
  | 'en_LR'
  | 'en_LS'
  | 'en_LT'
  | 'lt_LT'
  | 'lt'
  | 'de_LU'
  | 'fr_LU'
  | 'en_LV'
  | 'lv_LV'
  | 'lv'
  | 'ar_LY'
  | 'en_LY'
  | 'ar_MA'
  | 'fr_MA'
  | 'fr_MC'
  | 'en_MD'
  | 'ru_MD'
  | 'en_ME'
  | 'sr_ME'
  | 'sr'
  | 'sr_RS'
  | 'en_MG'
  | 'fr_MG'
  | 'en_MH'
  | 'en_MK'
  | 'mk_MK'
  | 'mk'
  | 'en_ML'
  | 'fr_ML'
  | 'en_MN'
  | 'en_MO'
  | 'zh_MO'
  | 'ar_MR'
  | 'en_MR'
  | 'en_MT'
  | 'en_MU'
  | 'ar_MV'
  | 'en_MV'
  | 'en_MW'
  | 'en_MY'
  | 'en_MZ'
  | 'af_NA'
  | 'af_ZA'
  | 'af'
  | 'en_NA'
  | 'en_NE'
  | 'fr_NE'
  | 'en_NG'
  | 'es_NI'
  | 'en_NO'
  | 'nb_NO'
  | 'nb'
  | 'no_NO'
  | 'no'
  | 'en_NP'
  | 'ne_NP'
  | 'ne'
  | 'en_NR'
  | 'en_NZ'
  | 'ar_OM'
  | 'en_OM'
  | 'es_PA'
  | 'es_PE'
  | 'en_PG'
  | 'en_PH'
  | 'fp_PH'
  | 'fp'
  | 'en_PK'
  | 'ur_PK'
  | 'ur'
  | 'pl_PL'
  | 'pl'
  | 'ar_PS'
  | 'en_PS'
  | 'en_PT'
  | 'en_PW'
  | 'es_PY'
  | 'ar_QA'
  | 'en_QA'
  | 'en_RO'
  | 'ro_RO'
  | 'ro'
  | 'en_RS'
  | 'en_RW'
  | 'ar_SA'
  | 'en_SA'
  | 'en_SB'
  | 'en_SC'
  | 'fr_SC'
  | 'sv_SE'
  | 'sv'
  | 'en_SG'
  | 'zh_SG'
  | 'zh_HANS'
  | 'zh_CN'
  | 'en_SI'
  | 'sl_SI'
  | 'sl'
  | 'en_SK'
  | 'sk_SK'
  | 'sk'
  | 'en_SL'
  | 'en_SM'
  | 'it_SM'
  | 'en_SN'
  | 'fr_SN'
  | 'en_SR'
  | 'nl_SR'
  | 'en_ST'
  | 'pt_ST'
  | 'es_SV'
  | 'en_SZ'
  | 'en_TD'
  | 'fr_TD'
  | 'en_TG'
  | 'fr_TG'
  | 'en_TH'
  | 'th_TH'
  | 'en_TJ'
  | 'ru_TJ'
  | 'en_TL'
  | 'pt_TL'
  | 'ar_TN'
  | 'fr_TN'
  | 'en_TO'
  | 'en_TR'
  | 'tr_TR'
  | 'tr'
  | 'en_TT'
  | 'en_TV'
  | 'en_TZ'
  | 'sw_TZ'
  | 'en_UA'
  | 'uk_UA'
  | 'uk'
  | 'en_UG'
  | 'sw_UG'
  | 'en_US'
  | 'es_UY'
  | 'en_UZ'
  | 'en_VC'
  | 'en_VE'
  | 'es_VE'
  | 'en_VN'
  | 'vi_VN'
  | 'en_VU'
  | 'fr_VU'
  | 'en_WS'
  | 'en_XK'
  | 'sr_XK'
  | 'en_ZA'
  | 'zu_ZA'
  | 'zu'
  | 'en_ZM'
  | 'en_ZW';
export type Directionality = 'tb-lr' | 'tb-rl';

export type MarketLocale = {
  name: string;
  languages: Array<LanguageCode>;
  dir: Directionality;
  mainLanguage: LanguageCode;
};

export type MarketLocales = Record<Locale, MarketLocale>;

export const marketLocales: MarketLocales = {
  en: {
    name: 'English (en)',
    languages: ['en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  ad: {
    name: 'Andorra (es)',
    languages: ['es_AD', 'es_ES', 'es'],
    dir: 'tb-lr',
    mainLanguage: 'es',
  },
  'ad-ca': {
    name: 'Andorra (ca)',
    languages: ['ca_AD', 'ca_ES', 'ca', 'es_ES', 'es'],
    dir: 'tb-lr',
    mainLanguage: 'ca',
  },
  'ae-ar': {
    name: 'United Arab Emirates (ar)',
    languages: ['ar_AE', 'ar'],
    dir: 'tb-rl',
    mainLanguage: 'ar',
  },
  'ae-en': {
    name: 'United Arab Emirates (en)',
    languages: ['en_AE'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  ag: {
    name: 'Antigua and Barbuda (en)',
    languages: ['en_AG'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  al: {
    name: 'Albania (en)',
    languages: ['en_AL'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  am: {
    name: 'Armenia (en)',
    languages: ['en_AM'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'ao-en': {
    name: 'Angola (en)',
    languages: ['en_AO', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'ao-pt': {
    name: 'Angola (pt)',
    languages: ['pt_AO', 'pt', 'pt_PT'],
    dir: 'tb-lr',
    mainLanguage: 'pt',
  },
  ar: {
    name: 'Argentina (es)',
    languages: ['es_LA', 'es_419', 'es'],
    dir: 'tb-lr',
    mainLanguage: 'es',
  },
  at: {
    name: 'Austria (de)',
    languages: ['de_AT', 'de_DE', 'de'],
    dir: 'tb-lr',
    mainLanguage: 'de',
  },
  au: {
    name: 'Australia (en)',
    languages: ['en_AU'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'az-az': {
    name: 'Azerbaijan (az)',
    languages: ['az_AZ', 'az'],
    dir: 'tb-lr',
    mainLanguage: 'az',
  },
  'az-en': {
    name: 'Azerbaijan (en)',
    languages: ['en_AZ', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  ba: {
    name: 'Bosnia and Herzegovina (en)',
    languages: ['en_BA'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'ba-bs': {
    name: 'Bosnia and Herzegovina (bs)',
    languages: ['bs_BA', 'bs'],
    dir: 'tb-lr',
    mainLanguage: 'bs',
  },
  bb: {
    name: 'Barbados (en)',
    languages: ['en_BB'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'bd-bn': {
    name: 'Bangladesh (bn)',
    languages: ['bn_BD', 'bn', 'bn_IN'],
    dir: 'tb-lr',
    mainLanguage: 'bn',
  },
  'bd-en': {
    name: 'Bangladesh (en)',
    languages: ['en_BD'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'be-fr': {
    name: 'Belgium (fr)',
    languages: ['fr_BE', 'fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  'be-nl': {
    name: 'Belgium (nl)',
    languages: ['nl_BE', 'nl_NL', 'nl'],
    dir: 'tb-lr',
    mainLanguage: 'nl',
  },
  'bf-en': {
    name: 'Burkina Faso (en)',
    languages: ['en_BF', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'bf-fr': {
    name: 'Burkina Faso (fr)',
    languages: ['fr_BF', 'fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  'bg-bg': {
    name: 'Bulgaria (bg)',
    languages: ['bg_BG', 'bg'],
    dir: 'tb-lr',
    mainLanguage: 'bg',
  },
  'bg-en': {
    name: 'Bulgaria (en)',
    languages: ['en_BG', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'bh-ar': {
    name: 'Bahrain (ar)',
    languages: ['ar_BH', 'ar'],
    dir: 'tb-rl',
    mainLanguage: 'ar',
  },
  'bh-en': {
    name: 'Bahrain (en)',
    languages: ['en_BH'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'bi-en': {
    name: 'Burundi (en)',
    languages: ['en_BI'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'bi-fr': {
    name: 'Burundi (fr)',
    languages: ['fr_BI', 'fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  'bj-en': {
    name: 'Benin (en)',
    languages: ['en_BJ', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'bj-fr': {
    name: 'Benin (fr)',
    languages: ['fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  'bn-en': {
    name: 'Brunei Darussalam (en)',
    languages: ['en_BW'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'bn-ms': {
    name: 'Brunei Darussalam (ms)',
    languages: ['ms_BW', 'ms_MY', 'ms'],
    dir: 'tb-lr',
    mainLanguage: 'ms',
  },
  bo: {
    name: 'Bolivia (es)',
    languages: ['es_BO', 'es_LA', 'es_419', 'es'],
    dir: 'tb-lr',
    mainLanguage: 'es',
  },
  'br-en': {
    name: 'Brazil (en)',
    languages: ['en_BR', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'br-pt': {
    name: 'Brazil (pt)',
    languages: ['pt_BR', 'pt_PT', 'pt'],
    dir: 'tb-lr',
    mainLanguage: 'pt',
  },
  bs: {
    name: 'The Bahamas (en)',
    languages: ['en_BS'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  bt: {
    name: 'Bhutan (en)',
    languages: ['en_BT'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  bw: {
    name: 'Botswana (en)',
    languages: ['en_BW'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'by-en': {
    name: 'Belarus (en)',
    languages: ['en_BY'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'by-ru': {
    name: 'Belarus (ru)',
    languages: ['ru_BY', 'ru_RU', 'ru'],
    dir: 'tb-lr',
    mainLanguage: 'ru',
  },
  'bz-en': {
    name: 'Belize (en)',
    languages: ['en_BZ'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'bz-es': {
    name: 'Belize (es)',
    languages: ['es_BZ', 'es_LA', 'es_419', 'es'],
    dir: 'tb-lr',
    mainLanguage: 'es',
  },
  'ca-en': {
    name: 'Canada (en)',
    languages: ['en_CA', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'ca-fr': {
    name: 'Canada (fr)',
    languages: ['fr_CA', 'fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  'cd-fr': {
    name: 'Democratic Republic of Congo (fr)',
    languages: ['fr_CD', 'fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  'cd-sw': {
    name: 'Democratic Republic of Congo (sw)',
    languages: ['sw_KE', 'sw'],
    dir: 'tb-lr',
    mainLanguage: 'sw',
  },
  'cg-en': {
    name: 'Republic of the Congo (en)',
    languages: ['en_CG'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'cg-fr': {
    name: 'Republic of the Congo (fr)',
    languages: ['fr_CG', 'fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  'ch-de': {
    name: 'Switzerland (de)',
    languages: ['de_CH', 'de_DE', 'de'],
    dir: 'tb-lr',
    mainLanguage: 'de',
  },
  'ch-fr': {
    name: 'Switzerland (fr)',
    languages: ['fr_CH', 'fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  'ci-en': {
    name: "Cote d'Ivoire (en)",
    languages: ['en_CI', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'ci-fr': {
    name: "Cote d'Ivoire (fr)",
    languages: ['fr_CI', 'fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  cl: {
    name: 'Chile (es)',
    languages: ['es_CL', 'es_LA', 'es_419', 'es'],
    dir: 'tb-lr',
    mainLanguage: 'es',
  },
  'cm-en': {
    name: 'Cameroon (en)',
    languages: ['en_CM'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'cm-fr': {
    name: 'Cameroon (fr)',
    languages: ['fr_CM', 'fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  'co-en': {
    name: 'Colombia (en)',
    languages: ['en_CO', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'co-es': {
    name: 'Colombia (es)',
    languages: ['es_CO', 'es_LA', 'es_419', 'es'],
    dir: 'tb-lr',
    mainLanguage: 'es',
  },
  cr: {
    name: 'Costa Rica (es)',
    languages: ['es_CR', 'es_LA', 'es_419', 'es'],
    dir: 'tb-lr',
    mainLanguage: 'es',
  },
  'cv-en': {
    name: 'Cabo Verde (en)',
    languages: ['en_CV', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'cv-pt': {
    name: 'Cabo Verde (pt)',
    languages: ['pt_CV', 'pt', 'pt_PT'],
    dir: 'tb-lr',
    mainLanguage: 'pt',
  },
  'cw-en': {
    name: 'Curacao (en)',
    languages: ['en_CW'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'cw-nl': {
    name: 'Curacao (nl)',
    languages: ['nl_CW', 'nl_NL', 'nl'],
    dir: 'tb-lr',
    mainLanguage: 'nl',
  },
  cy: {
    name: 'Cyprus (en)',
    languages: ['en_CY', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  cz: {
    name: 'Czech Republic (cs_CZ)',
    languages: ['cs_CZ'],
    dir: 'tb-lr',
    mainLanguage: 'cs_CZ',
  },
  de: {
    name: 'Germany (de)',
    languages: ['de_DE', 'de'],
    dir: 'tb-lr',
    mainLanguage: 'de',
  },
  'de-en': {
    name: 'Germany (en_GB)',
    languages: ['en'],
    dir: 'tb-lr',
    mainLanguage: 'en_GB',
  },
  'dj-en': {
    name: 'Djibouti (en)',
    languages: ['en_DJ', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'dj-fr': {
    name: 'Djibouti (fr)',
    languages: ['fr_DJ', 'fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  'dk-da': {
    name: 'Denmark (da)',
    languages: ['da_DK'],
    dir: 'tb-lr',
    mainLanguage: 'da',
  },
  'dk-en': {
    name: 'Denmark (en)',
    languages: ['en_DK'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  dm: {
    name: 'Dominica (en)',
    languages: ['en_DM'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  do: {
    name: 'Dominican Republic (es)',
    languages: ['es_DO', 'es_LA', 'es_419', 'es'],
    dir: 'tb-lr',
    mainLanguage: 'es',
  },
  'dz-ar': {
    name: 'Algeria (ar)',
    languages: ['ar_DZ', 'ar'],
    dir: 'tb-rl',
    mainLanguage: 'ar',
  },
  'dz-fr': {
    name: 'Algeria (fr)',
    languages: ['fr_DZ', 'fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  ec: {
    name: 'Ecuador (es)',
    languages: ['es_EC', 'es_LA', 'es_419', 'es'],
    dir: 'tb-lr',
    mainLanguage: 'es',
  },
  'ee-en': {
    name: 'Estonia (en)',
    languages: ['en_EE'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'ee-et': {
    name: 'Estonia (et)',
    languages: ['et_EE', 'et'],
    dir: 'tb-lr',
    mainLanguage: 'et',
  },
  'eg-ar': {
    name: 'Egypt (ar)',
    languages: ['ar_EG', 'ar'],
    dir: 'tb-rl',
    mainLanguage: 'ar',
  },
  'eg-en': {
    name: 'Egypt (en)',
    languages: ['en_EG'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  es: {
    name: 'Spain (es)',
    languages: ['es_ES', 'es'],
    dir: 'tb-lr',
    mainLanguage: 'es',
  },
  'es-ca': {
    name: 'Spain (ca)',
    languages: ['ca_ES', 'ca', 'es_ES', 'es'],
    dir: 'tb-lr',
    mainLanguage: 'ca',
  },
  'es-eu': {
    name: 'Spain (eu)',
    languages: ['eu_ES', 'eu', 'es_ES', 'es'],
    dir: 'tb-lr',
    mainLanguage: 'eu',
  },
  'es-gl': {
    name: 'Spain (gl)',
    languages: ['gl_ES', 'gl', 'es_ES', 'es'],
    dir: 'tb-lr',
    mainLanguage: 'gl',
  },
  'et-am': {
    name: 'Ethiopia (am)',
    languages: ['am_ET', 'am'],
    dir: 'tb-lr',
    mainLanguage: 'am',
  },
  'et-en': {
    name: 'Ethiopia (en)',
    languages: ['en_ET', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  fi: {
    name: 'Finland (fi)',
    languages: ['fi_FI', 'fi'],
    dir: 'tb-lr',
    mainLanguage: 'fi',
  },
  fj: {
    name: 'Fiji (en)',
    languages: ['en_FJ'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  fm: {
    name: 'Micronesia, Fed. Sts. (en)',
    languages: ['en_FM'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  fr: {
    name: 'France (fr)',
    languages: ['fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  'ga-en': {
    name: 'Gabon (en)',
    languages: ['en_GA', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'ga-fr': {
    name: 'Gabon (fr)',
    languages: ['fr_GA', 'fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  gd: {
    name: 'Grenada (en)',
    languages: ['en_GD'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  ge: {
    name: 'Georgia (en)',
    languages: ['en_GA'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  gh: {
    name: 'Ghana (en)',
    languages: ['en_GH'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  gm: {
    name: 'The Gambia (en)',
    languages: ['en_GM'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'gn-en': {
    name: 'Guinea (en)',
    languages: ['en_GN', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'gn-fr': {
    name: 'Guinea (fr)',
    languages: ['fr_GN', 'fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  'gq-en': {
    name: 'Equatorial Guinea (en)',
    languages: ['en_GQ', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'gq-es': {
    name: 'Equatorial Guinea (es)',
    languages: ['es_GQ', 'es_ES', 'es'],
    dir: 'tb-lr',
    mainLanguage: 'es',
  },
  gr: {
    name: 'Greece (el)',
    languages: ['el_GR', 'el'],
    dir: 'tb-lr',
    mainLanguage: 'el',
  },
  gt: {
    name: 'Guatemala (es)',
    languages: ['es_GT', 'es_LA', 'es_419', 'es'],
    dir: 'tb-lr',
    mainLanguage: 'es',
  },
  'gw-en': {
    name: 'Guinea-Bissau (en)',
    languages: ['en_GW', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'gw-pt': {
    name: 'Guinea-Bissau (pt)',
    languages: ['pt_GW', 'pt', 'pt_PT'],
    dir: 'tb-lr',
    mainLanguage: 'pt',
  },
  gy: {
    name: 'Guyana (en)',
    languages: ['en_GY'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'hk-en': {
    name: 'Hong Kong (en)',
    languages: ['en_HK'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'hk-zh': {
    name: 'Hong Kong (zh_HANT)',
    languages: ['zh_HANT', 'zh_TW'],
    dir: 'tb-lr',
    mainLanguage: 'zh_HANT',
  },
  hn: {
    name: 'Honduras (es)',
    languages: ['es_HN', 'es_LA', 'es_419', 'es'],
    dir: 'tb-lr',
    mainLanguage: 'es',
  },
  'hr-en': {
    name: 'Croatia (en)',
    languages: ['en_HR'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'hr-hr': {
    name: 'Croatia (hr)',
    languages: ['hr_HR', 'hr'],
    dir: 'tb-lr',
    mainLanguage: 'hr',
  },
  'ht-en': {
    name: 'Haiti (en)',
    languages: ['en_HT', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'ht-fr': {
    name: 'Haiti (fr)',
    languages: ['fr_HT', 'fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  hu: {
    name: 'Hungary (hu)',
    languages: ['hu_HU', 'hu'],
    dir: 'tb-lr',
    mainLanguage: 'hu',
  },
  'id-en': {
    name: 'Indonesia (en)',
    languages: ['en_ID', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'id-id': {
    name: 'Indonesia (id)',
    languages: ['id_ID', 'id'],
    dir: 'tb-lr',
    mainLanguage: 'id',
  },
  ie: {
    name: 'Ireland (en)',
    languages: ['en_IE'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'il-en': {
    name: 'Israel (en)',
    languages: ['en_IL'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'il-he': {
    name: 'Israel (he)',
    languages: ['he_IL'],
    dir: 'tb-rl',
    mainLanguage: 'he',
  },
  'in-en': {
    name: 'India (en)',
    languages: ['en_IN', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'in-hi': {
    name: 'India (hi)',
    languages: ['hi_IN', 'hi'],
    dir: 'tb-lr',
    mainLanguage: 'hi',
  },
  'iq-ar': {
    name: 'Iraq (ar)',
    languages: ['ar_IQ', 'ar'],
    dir: 'tb-rl',
    mainLanguage: 'ar',
  },
  'iq-en': {
    name: 'Iraq (en)',
    languages: ['en_IQ'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'is-en': {
    name: 'Iceland (en)',
    languages: ['en_IS'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'is-is': {
    name: 'Iceland (is)',
    languages: ['is_IS', 'is'],
    dir: 'tb-lr',
    mainLanguage: 'is',
  },
  it: {
    name: 'Italy (it)',
    languages: ['it_IT', 'it'],
    dir: 'tb-lr',
    mainLanguage: 'it',
  },
  jm: {
    name: 'Jamaica (en)',
    languages: ['en_JM'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'jo-ar': {
    name: 'Jordan (ar)',
    languages: ['ar_JO', 'ar'],
    dir: 'tb-rl',
    mainLanguage: 'ar',
  },
  'jo-en': {
    name: 'Jordan (en)',
    languages: ['en_JO'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  jp: {
    name: 'Japan (ja)',
    languages: ['ja_JP', 'ja'],
    dir: 'tb-lr',
    mainLanguage: 'ja',
  },
  'ke-en': {
    name: 'Kenya (en)',
    languages: ['en_KE'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'ke-sw': {
    name: 'Kenya (sw)',
    languages: ['sw_KE', 'sw'],
    dir: 'tb-lr',
    mainLanguage: 'sw',
  },
  'kg-en': {
    name: 'Kyrgyz Republic (en)',
    languages: ['en_KG', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'kg-ru': {
    name: 'Kyrgyz Republic (ru)',
    languages: ['ru_KG', 'ru_RU', 'ru'],
    dir: 'tb-lr',
    mainLanguage: 'ru',
  },
  kh: {
    name: 'Cambodia (en)',
    languages: ['en_KH'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  ki: {
    name: 'Kiribati (en)',
    languages: ['en_KI'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'km-en': {
    name: 'Comoros (en)',
    languages: ['en_KM', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'km-fr': {
    name: 'Comoros (fr)',
    languages: ['fr_KM', 'fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  kn: {
    name: 'St. Kitts and Nevis (en)',
    languages: ['en_KN'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'kr-en': {
    name: 'South Korea (en)',
    languages: ['en_KR'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'kr-ko': {
    name: 'South Korea (ko)',
    languages: ['ko_KR', 'ko'],
    dir: 'tb-lr',
    mainLanguage: 'ko',
  },
  'kw-ar': {
    name: 'Kuwait (ar)',
    languages: ['ar_KW', 'ar'],
    dir: 'tb-rl',
    mainLanguage: 'ar',
  },
  'kw-en': {
    name: 'Kuwait (en)',
    languages: ['en_KW'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'kz-en': {
    name: 'Kazakhstan (en)',
    languages: ['en_KZ'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'kz-ru': {
    name: 'Kazakhstan (ru)',
    languages: ['ru_KZ', 'ru_RU', 'ru'],
    dir: 'tb-lr',
    mainLanguage: 'ru',
  },
  la: {
    name: 'Lao PDR (en)',
    languages: ['en_LA'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'lb-ar': {
    name: 'Lebanon (ar)',
    languages: ['ar_LB', 'ar'],
    dir: 'tb-rl',
    mainLanguage: 'ar',
  },
  'lb-en': {
    name: 'Lebanon (en)',
    languages: ['en_LB'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  lc: {
    name: 'St. Lucia (en)',
    languages: ['en_LC'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  li: {
    name: 'Liechtenstein (de)',
    languages: ['de_LI', 'de_DE', 'de'],
    dir: 'tb-lr',
    mainLanguage: 'de',
  },
  'lk-en': {
    name: 'Sri Lanka (en)',
    languages: ['en_LK'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'lk-ta': {
    name: 'Sri Lanka (ta)',
    languages: ['ta_LK', 'ta_IN', 'ta'],
    dir: 'tb-lr',
    mainLanguage: 'ta',
  },
  lr: {
    name: 'Liberia (en)',
    languages: ['en_LR'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  ls: {
    name: 'Lesotho (en)',
    languages: ['en_LS'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'lt-en': {
    name: 'Lithuania (en)',
    languages: ['en_LT'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'lt-lt': {
    name: 'Lithuania (lt)',
    languages: ['lt_LT', 'lt'],
    dir: 'tb-lr',
    mainLanguage: 'lt',
  },
  'lu-de': {
    name: 'Luxembourg (de)',
    languages: ['de_LU', 'de_DE', 'de'],
    dir: 'tb-lr',
    mainLanguage: 'de',
  },
  'lu-fr': {
    name: 'Luxembourg (fr)',
    languages: ['fr_LU', 'fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  'lv-en': {
    name: 'Latvia (en)',
    languages: ['en_LV'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'lv-lv': {
    name: 'Latvia (lv)',
    languages: ['lv_LV', 'lv'],
    dir: 'tb-lr',
    mainLanguage: 'lv',
  },
  'ly-ar': {
    name: 'Libya (ar)',
    languages: ['ar_LY', 'ar'],
    dir: 'tb-rl',
    mainLanguage: 'ar',
  },
  'ly-en': {
    name: 'Libya (en)',
    languages: ['en_LY'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'ma-ar': {
    name: 'Morocco (ar)',
    languages: ['ar_MA', 'ar'],
    dir: 'tb-rl',
    mainLanguage: 'ar',
  },
  'ma-fr': {
    name: 'Morocco (fr)',
    languages: ['fr_MA', 'fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  mc: {
    name: 'Monaco (fr)',
    languages: ['fr_MC', 'fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  'md-en': {
    name: 'Moldova (en)',
    languages: ['en_MD'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'md-ru': {
    name: 'Moldova (ru)',
    languages: ['ru_MD', 'ru_RU', 'ru'],
    dir: 'tb-lr',
    mainLanguage: 'ru',
  },
  'me-en': {
    name: 'Montenegro (en)',
    languages: ['en_ME'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'me-sr': {
    name: 'Montenegro (sr)',
    languages: ['sr_ME', 'sr', 'sr_RS'],
    dir: 'tb-lr',
    mainLanguage: 'sr',
  },
  'mg-en': {
    name: 'Madagascar (en)',
    languages: ['en_MG', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'mg-fr': {
    name: 'Madagascar (fr)',
    languages: ['fr_MG', 'fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  mh: {
    name: 'Marshall Islands (en)',
    languages: ['en_MH'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  mk: {
    name: 'North Macedonia (en)',
    languages: ['en_MK'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'mk-mk': {
    name: 'North Macedonia (mk)',
    languages: ['mk_MK', 'mk'],
    dir: 'tb-lr',
    mainLanguage: 'mk',
  },
  'ml-en': {
    name: 'Mali (en)',
    languages: ['en_ML', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'ml-fr': {
    name: 'Mali (fr)',
    languages: ['fr_ML', 'fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  mn: {
    name: 'Mongolia (en)',
    languages: ['en_MN'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'mo-en': {
    name: 'Macao SAR, China (en)',
    languages: ['en_MO', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'mo-zh': {
    name: 'Macao SAR, China (zh_HANT)',
    languages: ['zh_MO', 'zh_HANT', 'zh_TW'],
    dir: 'tb-lr',
    mainLanguage: 'zh_HANT',
  },
  'mr-ar': {
    name: 'Mauritania (ar)',
    languages: ['ar_MR', 'ar'],
    dir: 'tb-rl',
    mainLanguage: 'ar',
  },
  'mr-en': {
    name: 'Mauritania (en)',
    languages: ['en_MR', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  mt: {
    name: 'Malta (en)',
    languages: ['en_MT', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  mu: {
    name: 'Mauritius (en)',
    languages: ['en_MU'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'mv-ar': {
    name: 'Maldives (ar)',
    languages: ['ar_MV', 'ar'],
    dir: 'tb-rl',
    mainLanguage: 'ar',
  },
  'mv-en': {
    name: 'Maldives (en)',
    languages: ['en_MV'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  mw: {
    name: 'Malawi (en)',
    languages: ['en_MW'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  mx: {
    name: 'Mexico (es)',
    languages: ['es_LA', 'es_419', 'es'],
    dir: 'tb-lr',
    mainLanguage: 'es',
  },
  'mx-en': {
    name: 'Mexico (en)',
    languages: ['en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'my-en': {
    name: 'Malaysia (en)',
    languages: ['en_MY'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'my-ms': {
    name: 'Malaysia (ms)',
    languages: ['ms_MY', 'ms'],
    dir: 'tb-lr',
    mainLanguage: 'ms',
  },
  'mz-en': {
    name: 'Mozambique (en)',
    languages: ['en_MZ', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'mz-pt': {
    name: 'Mozambique (pt)',
    languages: ['pt_PT', 'pt'],
    dir: 'tb-lr',
    mainLanguage: 'pt',
  },
  'na-af': {
    name: 'Namibia (af)',
    languages: ['af_NA', 'af_ZA', 'af'],
    dir: 'tb-lr',
    mainLanguage: 'af',
  },
  'na-en': {
    name: 'Namibia (en)',
    languages: ['en_NA'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'ne-en': {
    name: 'Niger (en)',
    languages: ['en_NE', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'ne-fr': {
    name: 'Niger (fr)',
    languages: ['fr_NE', 'fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  ng: {
    name: 'Nigeria (en)',
    languages: ['en_NG'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  ni: {
    name: 'Nicaragua (es)',
    languages: ['es_NI', 'es_LA', 'es_419', 'es'],
    dir: 'tb-lr',
    mainLanguage: 'es',
  },
  nl: {
    name: 'Netherlands (nl)',
    languages: ['nl_NL', 'nl'],
    dir: 'tb-lr',
    mainLanguage: 'nl',
  },
  'nl-en': {
    name: 'Netherlands (en_GB)',
    languages: ['en'],
    dir: 'tb-lr',
    mainLanguage: 'en_GB',
  },
  'no-en': {
    name: 'Norway (en)',
    languages: ['en_NO'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'no-nb': {
    name: 'Norway (nb)',
    languages: ['nb_NO', 'nb', 'no_NO', 'no'],
    dir: 'tb-lr',
    mainLanguage: 'nb',
  },
  'np-en': {
    name: 'Nepal (en)',
    languages: ['en_NP', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'np-ne': {
    name: 'Nepal (ne)',
    languages: ['ne_NP', 'ne'],
    dir: 'tb-lr',
    mainLanguage: 'ne',
  },
  nr: {
    name: 'Nauru (en)',
    languages: ['en_NR'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  nz: {
    name: 'New Zealand (en)',
    languages: ['en_NZ'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'om-ar': {
    name: 'Oman (ar)',
    languages: ['ar_OM', 'ar'],
    dir: 'tb-rl',
    mainLanguage: 'ar',
  },
  'om-en': {
    name: 'Oman (en)',
    languages: ['en_OM'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  pa: {
    name: 'Panama (es)',
    languages: ['es_PA', 'es_LA', 'es_419', 'es'],
    dir: 'tb-lr',
    mainLanguage: 'es',
  },
  pe: {
    name: 'Peru (es)',
    languages: ['es_PE', 'es_LA', 'es_419', 'es'],
    dir: 'tb-lr',
    mainLanguage: 'es',
  },
  pg: {
    name: 'Papua New Guinea (en)',
    languages: ['en_PG'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'ph-en': {
    name: 'Philippines (en)',
    languages: ['en_PH'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'ph-tl': {
    name: 'Philippines (fp)',
    languages: ['fp_PH', 'fp'],
    dir: 'tb-lr',
    mainLanguage: 'fp',
  },
  'pk-en': {
    name: 'Pakistan (en)',
    languages: ['en_PK'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'pk-ur': {
    name: 'Pakistan (ur)',
    languages: ['ur_PK', 'ur'],
    dir: 'tb-rl',
    mainLanguage: 'ur',
  },
  pl: {
    name: 'Poland (pl)',
    languages: ['pl_PL', 'pl'],
    dir: 'tb-lr',
    mainLanguage: 'pl',
  },
  'ps-ar': {
    name: 'Palestine (ar)',
    languages: ['ar_PS', 'ar'],
    dir: 'tb-rl',
    mainLanguage: 'ar',
  },
  'ps-en': {
    name: 'Palestine (en)',
    languages: ['en_PS'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'pt-en': {
    name: 'Portugal (en)',
    languages: ['en_PT'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'pt-pt': {
    name: 'Portugal (pt)',
    languages: ['pt_PT', 'pt'],
    dir: 'tb-lr',
    mainLanguage: 'pt',
  },
  pw: {
    name: 'Palau (en)',
    languages: ['en_PW'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  py: {
    name: 'Paraguay (es)',
    languages: ['es_PY', 'es_LA', 'es_419', 'es'],
    dir: 'tb-lr',
    mainLanguage: 'es',
  },
  'qa-ar': {
    name: 'Qatar (ar)',
    languages: ['ar_QA', 'ar'],
    dir: 'tb-rl',
    mainLanguage: 'ar',
  },
  'qa-en': {
    name: 'Qatar (en)',
    languages: ['en_QA'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'ro-en': {
    name: 'Romania (en)',
    languages: ['en_RO'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'ro-ro': {
    name: 'Romania (ro)',
    languages: ['ro_RO', 'ro'],
    dir: 'tb-lr',
    mainLanguage: 'ro',
  },
  'rs-en': {
    name: 'Serbia (en)',
    languages: ['en_RS'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'rs-sr': {
    name: 'Serbia (sr)',
    languages: ['sr_RS', 'sr'],
    dir: 'tb-lr',
    mainLanguage: 'sr',
  },
  rw: {
    name: 'Rwanda (en)',
    languages: ['en_RW'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'sa-ar': {
    name: 'Saudi Arabia (ar)',
    languages: ['ar_SA', 'ar'],
    dir: 'tb-rl',
    mainLanguage: 'ar',
  },
  'sa-en': {
    name: 'Saudi Arabia (en)',
    languages: ['en_SA'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  sb: {
    name: 'Solomon Islands (en)',
    languages: ['en_SB'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'sc-en': {
    name: 'Seychelles (en)',
    languages: ['en_SC'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'sc-fr': {
    name: 'Seychelles (fr)',
    languages: ['fr_SC', 'fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  se: {
    name: 'Sweden (sv)',
    languages: ['sv_SE', 'sv'],
    dir: 'tb-lr',
    mainLanguage: 'sv',
  },
  'sg-en': {
    name: 'Singapore (en)',
    languages: ['en_SG', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'sg-zh': {
    name: 'Singapore (zh_HANS)',
    languages: ['zh_SG', 'zh_HANS', 'zh_CN'],
    dir: 'tb-lr',
    mainLanguage: 'zh_HANS',
  },
  'si-en': {
    name: 'Slovenia (en)',
    languages: ['en_SI'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'si-sl': {
    name: 'Slovenia (sl)',
    languages: ['sl_SI', 'sl'],
    dir: 'tb-lr',
    mainLanguage: 'sl',
  },
  'sk-en': {
    name: 'Slovakia (en)',
    languages: ['en_SK', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'sk-sk': {
    name: 'Slovakia (sk)',
    languages: ['sk_SK', 'sk'],
    dir: 'tb-lr',
    mainLanguage: 'sk',
  },
  sl: {
    name: 'Sierra Leone (en)',
    languages: ['en_SL'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'sm-en': {
    name: 'San Marino (en)',
    languages: ['en_SM', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'sm-it': {
    name: 'San Marino (it)',
    languages: ['it_SM', 'it_IT', 'it'],
    dir: 'tb-lr',
    mainLanguage: 'it',
  },
  'sn-en': {
    name: 'Senegal (en)',
    languages: ['en_SN', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'sn-fr': {
    name: 'Senegal (fr)',
    languages: ['fr_SN', 'fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  'sr-en': {
    name: 'Suriname (en)',
    languages: ['en_SR', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'sr-nl': {
    name: 'Suriname (nl)',
    languages: ['nl_SR', 'nl_NL', 'nl'],
    dir: 'tb-lr',
    mainLanguage: 'nl',
  },
  'st-en': {
    name: 'Sao Tome and Principe (en)',
    languages: ['en_ST', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'st-pt': {
    name: 'Sao Tome and Principe (pt)',
    languages: ['pt_ST', 'pt_PT', 'pt'],
    dir: 'tb-lr',
    mainLanguage: 'pt',
  },
  sv: {
    name: 'El Salvador (es)',
    languages: ['es_SV', 'es_LA', 'es_419', 'es'],
    dir: 'tb-lr',
    mainLanguage: 'es',
  },
  sz: {
    name: 'Eswatini (en)',
    languages: ['en_SZ'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'td-en': {
    name: 'Chad (en)',
    languages: ['en_TD', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'td-fr': {
    name: 'Chad (fr)',
    languages: ['fr_TD', 'fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  'tg-en': {
    name: 'Togo (en)',
    languages: ['en_TG', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'tg-fr': {
    name: 'Togo (fr)',
    languages: ['fr_TG', 'fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  'th-en': {
    name: 'Thailand (en)',
    languages: ['en_TH', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'th-th': {
    name: 'Thailand (th_TH)',
    languages: ['th_TH'],
    dir: 'tb-lr',
    mainLanguage: 'th_TH',
  },
  'tj-en': {
    name: 'Tajikistan (en)',
    languages: ['en_TJ'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'tj-ru': {
    name: 'Tajikistan (ru)',
    languages: ['ru_TJ', 'ru_RU', 'ru'],
    dir: 'tb-lr',
    mainLanguage: 'ru',
  },
  'tl-en': {
    name: 'Timor-Leste (en)',
    languages: ['en_TL', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'tl-pt': {
    name: 'Timor-Leste (pt)',
    languages: ['pt_TL', 'pt', 'pt_PT'],
    dir: 'tb-lr',
    mainLanguage: 'pt',
  },
  'tn-ar': {
    name: 'Tunisia (ar)',
    languages: ['ar_TN', 'ar'],
    dir: 'tb-rl',
    mainLanguage: 'ar',
  },
  'tn-fr': {
    name: 'Tunisia (fr)',
    languages: ['fr_TN', 'fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  to: {
    name: 'Tonga (en)',
    languages: ['en_TO'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'tr-en': {
    name: 'Turkey (en)',
    languages: ['en_TR', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'tr-tr': {
    name: 'Turkey (tr)',
    languages: ['tr_TR', 'tr'],
    dir: 'tb-lr',
    mainLanguage: 'tr',
  },
  tt: {
    name: 'Trinidad and Tobago (en)',
    languages: ['en_TT'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  tv: {
    name: 'Tuvalu (en)',
    languages: ['en_TV'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  tw: {
    name: 'Taiwan (zh_HANT)',
    languages: ['zh_TW', 'zh_HANT'],
    dir: 'tb-lr',
    mainLanguage: 'zh_HANT',
  },
  'tz-en': {
    name: 'Tanzania (en)',
    languages: ['en_TZ'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'tz-sw': {
    name: 'Tanzania (sw)',
    languages: ['sw_TZ', 'sw'],
    dir: 'tb-lr',
    mainLanguage: 'sw',
  },
  'ua-en': {
    name: 'Ukraine (en)',
    languages: ['en_UA'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'ua-uk': {
    name: 'Ukraine (uk)',
    languages: ['uk_UA', 'uk'],
    dir: 'tb-lr',
    mainLanguage: 'uk',
  },
  'ug-en': {
    name: 'Uganda (en)',
    languages: ['en_UG'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'ug-sw': {
    name: 'Uganda (sw)',
    languages: ['sw_UG', 'sw'],
    dir: 'tb-lr',
    mainLanguage: 'sw',
  },
  uk: {
    name: 'United Kingdom (en_GB)',
    languages: ['en_GB', 'en'],
    dir: 'tb-lr',
    mainLanguage: 'en_GB',
  },
  us: {
    name: 'United States (en)',
    languages: ['en_US'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  uy: {
    name: 'Uruguay (es)',
    languages: ['es_UY', 'es_LA', 'es_419', 'es'],
    dir: 'tb-lr',
    mainLanguage: 'es',
  },
  uz: {
    name: 'Uzbekistan (en)',
    languages: ['en_UZ'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  vc: {
    name: 'St. Vincent and the Grenadines (en)',
    languages: ['en_VC'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  've-en': {
    name: 'Venezuela, RB (en)',
    languages: ['en_VE'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  've-es': {
    name: 'Venezuela, RB (es)',
    languages: ['es_VE', 'es_LA', 'es_419', 'es'],
    dir: 'tb-lr',
    mainLanguage: 'es',
  },
  'vn-en': {
    name: 'Vietnam (en)',
    languages: ['en_VN'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'vn-vi': {
    name: 'Vietnam (vi_VN)',
    languages: ['vi_VN'],
    dir: 'tb-lr',
    mainLanguage: 'vi_VN',
  },
  'vu-en': {
    name: 'Vanuatu (en)',
    languages: ['en_VU'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'vu-fr': {
    name: 'Vanuatu (fr)',
    languages: ['fr_VU', 'fr_FR', 'fr'],
    dir: 'tb-lr',
    mainLanguage: 'fr',
  },
  ws: {
    name: 'Samoa (en)',
    languages: ['en_WS'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'xk-en': {
    name: 'Kosovo (en)',
    languages: ['en_XK'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'xk-sr': {
    name: 'Kosovo (sr)',
    languages: ['sr_XK', 'sr', 'sr_RS'],
    dir: 'tb-lr',
    mainLanguage: 'sr',
  },
  'za-en': {
    name: 'South Africa (en)',
    languages: ['en_ZA'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  'za-zu': {
    name: 'South Africa (zu)',
    languages: ['zu_ZA', 'zu'],
    dir: 'tb-lr',
    mainLanguage: 'zu',
  },
  zm: {
    name: 'Zambia (en)',
    languages: ['en_ZM'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
  zw: {
    name: 'Zimbabwe (en)',
    languages: ['en_ZW'],
    dir: 'tb-lr',
    mainLanguage: 'en',
  },
};

export default marketLocales;
