import React from 'react';
import {
  Translator,
  getTranslationFunction,
  getDefaultTranslations,
  defaultLocale,
  Locale,
  Interpolator,
  getInterpolationFunction,
} from 'lib/i18n';

export type TranslationContextType = {
  locale: Locale;
  t: Translator;
  interpolate: Interpolator;
};

const defaultContext: TranslationContextType = {
  locale: defaultLocale,
  t: getTranslationFunction(getDefaultTranslations, {}),
  interpolate: getInterpolationFunction(getDefaultTranslations, {}),
};

export const TranslationContext =
  React.createContext<TranslationContextType>(defaultContext);
