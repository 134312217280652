import { createGlobalStyle } from 'styled-components';
import * as tokens from 'styles/index';

export const GlobalStyles = createGlobalStyle<{
  $darkTheme: boolean;
  $blackBackground?: boolean;
}>`
  :root {
    background: ${({ $darkTheme, $blackBackground }) => {
      if ($blackBackground) return tokens.black;
      return $darkTheme ? tokens.gray7 : tokens.white;
    }}
  }
  body {
    margin: inherit;

    // Reset box-sizing, as in https://ghe.spotify.net/encore/web/blob/beta/src/styles/global-styles.tsx
    * {
      box-sizing: border-box;
    }
    *::before,
    *::after {
      box-sizing: border-box;
    }

    width: 100%;
    position: relative;
    font-family: CircularSp, Helvetica, Arial, sans-serif;

    :lang(ar) *,
    :lang(ur) * {
      font-family: CircularSp-Arab, 'noto-arabic', 'Helvetica Neue', Helvetica, Arial, 'Hiragino Kaku Gothic Pro', 'Meiryo', 'MS Gothic', sans-serif !important;
      letter-spacing: 0 !important;
    }

    :lang(he) * {
      font-family: CircularSp-Hebr, Helvetica, Arial, sans-serif !important;
      letter-spacing: 0 !important;
    }

    :lang(bn) *,
    :lang(hi) *,
    :lang(ne) * {
      letter-spacing: 0 !important;
    }

    :lang(ko) * {
      word-break: keep-all;
    }
  }
`;
