import { LanguageCode } from 'lib/marketLocales';
export const localeLanguageMapping: { [key: string]: LanguageCode } = {
  en: 'en',
  ad: 'es_ES',
  'ad-ca': 'ca_ES',
  'ae-ar': 'ar',
  'ae-en': 'en',
  ag: 'en',
  al: 'en',
  am: 'en',
  'ao-en': 'en',
  'ao-pt': 'pt',
  ar: 'es_LA',
  at: 'de_DE',
  au: 'en',
  'az-az': 'az_AZ',
  'az-en': 'en',
  ba: 'en',
  'ba-bs': 'bs_BA',
  bb: 'en',
  'bd-bn': 'bn_BD',
  'bd-en': 'en',
  'be-fr': 'fr_FR',
  'be-nl': 'nl_NL',
  'bf-en': 'en',
  'bf-fr': 'fr_FR',
  'bg-bg': 'bg_BG',
  'bg-en': 'en',
  'bh-ar': 'ar',
  'bh-en': 'en',
  'bi-en': 'en',
  'bi-fr': 'fr_FR',
  'bj-en': 'en',
  'bj-fr': 'fr_FR',
  'bn-en': 'en',
  'bn-ms': 'ms_MY',
  bo: 'es_LA',
  'br-en': 'en',
  'br-pt': 'pt_BR',
  bs: 'en',
  bt: 'en',
  bw: 'en',
  'by-en': 'en',
  'by-ru': 'ru_RU',
  'bz-en': 'en',
  'bz-es': 'es_LA',
  'ca-en': 'en',
  'ca-fr': 'fr_CA',
  'cd-fr': 'fr_FR',
  'cd-sw': 'sw',
  'cg-en': 'en',
  'cg-fr': 'fr_FR',
  'ch-de': 'de_DE',
  'ch-fr': 'fr_FR',
  'ci-en': 'en',
  'ci-fr': 'fr_FR',
  cl: 'es_CL',
  'cm-en': 'en',
  'cm-fr': 'fr_FR',
  'co-en': 'en',
  'co-es': 'es_LA',
  cr: 'es_LA',
  'cv-en': 'en',
  'cv-pt': 'pt',
  'cw-en': 'en',
  'cw-nl': 'nl_NL',
  cy: 'en',
  cz: 'cs_CZ',
  de: 'de_DE',
  'de-en': 'en',
  'dj-en': 'en',
  'dj-fr': 'fr_FR',
  'dk-da': 'da_DK',
  'dk-en': 'en',
  dm: 'en',
  do: 'es_LA',
  'dz-ar': 'ar',
  'dz-fr': 'fr_FR',
  ec: 'es_LA',
  'ee-en': 'en',
  'ee-et': 'et_EE',
  'eg-ar': 'ar',
  'eg-en': 'en',
  es: 'es_ES',
  'es-ca': 'ca_ES',
  'es-eu': 'es_ES',
  'es-gl': 'es_ES',
  'et-am': 'am_ET',
  'et-en': 'en',
  fi: 'fi_FI',
  fj: 'en',
  fm: 'en',
  fr: 'fr_FR',
  'ga-en': 'en',
  'ga-fr': 'fr_FR',
  gd: 'en',
  ge: 'en',
  gh: 'en',
  gm: 'en',
  'gn-en': 'en',
  'gn-fr': 'fr_FR',
  'gq-en': 'en',
  'gq-es': 'es_ES',
  gr: 'el_GR',
  gt: 'es_LA',
  'gw-en': 'en',
  'gw-pt': 'pt',
  gy: 'en',
  'hk-en': 'en',
  'hk-zh': 'zh_HANT',
  hn: 'es_LA',
  'hr-en': 'en',
  'hr-hr': 'hr_HR',
  'ht-en': 'en',
  'ht-fr': 'fr_FR',
  hu: 'hu_HU',
  'id-en': 'en',
  'id-id': 'id_ID',
  ie: 'en',
  'il-en': 'en',
  'il-he': 'he_IL',
  'in-en': 'en',
  'in-hi': 'hi_IN',
  'iq-ar': 'ar',
  'iq-en': 'en',
  'is-en': 'en',
  'is-is': 'is_IS',
  it: 'it_IT',
  jm: 'en',
  'jo-ar': 'ar',
  'jo-en': 'en',
  jp: 'ja_JP',
  'ke-en': 'en',
  'ke-sw': 'sw',
  'kg-en': 'en',
  'kg-ru': 'ru_RU',
  kh: 'en',
  ki: 'en',
  'km-en': 'en',
  'km-fr': 'fr_FR',
  kn: 'en',
  'kr-en': 'en',
  'kr-ko': 'ko_KR',
  'kw-ar': 'ar',
  'kw-en': 'en',
  'kz-en': 'en',
  'kz-ru': 'ru_RU',
  la: 'en',
  'lb-ar': 'ar',
  'lb-en': 'en',
  lc: 'en',
  li: 'de_DE',
  'lk-en': 'en',
  'lk-ta': 'ta_IN',
  lr: 'en',
  ls: 'en',
  'lt-en': 'en',
  'lt-lt': 'lt_LT',
  'lu-de': 'de_DE',
  'lu-fr': 'fr_FR',
  'lv-en': 'en',
  'lv-lv': 'lv_LV',
  'ly-ar': 'ar',
  'ly-en': 'en',
  'ma-ar': 'ar',
  'ma-fr': 'fr_FR',
  mc: 'fr_FR',
  'md-en': 'en',
  'md-ru': 'ru_RU',
  'me-en': 'en',
  'me-sr': 'sr_RS',
  'mg-en': 'en',
  'mg-fr': 'fr_FR',
  mh: 'en',
  mk: 'en',
  'mk-mk': 'mk_MK',
  'ml-en': 'en',
  'ml-fr': 'fr_FR',
  mn: 'en',
  'mo-en': 'en',
  'mo-zh': 'zh_HANT',
  'mr-ar': 'ar',
  'mr-en': 'en',
  mt: 'en',
  mu: 'en',
  'mv-ar': 'ar',
  'mv-en': 'en',
  mw: 'en',
  mx: 'es_LA',
  'mx-en': 'en',
  'my-en': 'en',
  'my-ms': 'ms_MY',
  'mz-en': 'en',
  'mz-pt': 'pt_PT',
  'na-af': 'af_ZA',
  'na-en': 'en',
  'ne-en': 'en',
  'ne-fr': 'fr_FR',
  ng: 'en',
  ni: 'es_LA',
  nl: 'nl_NL',
  'nl-en': 'en',
  'no-en': 'en',
  'no-nb': 'nb_NO',
  'np-en': 'en',
  'np-ne': 'ne_NP',
  nr: 'en',
  nz: 'en',
  'om-ar': 'ar',
  'om-en': 'en',
  pa: 'es_LA',
  pe: 'es_LA',
  pg: 'en',
  'ph-en': 'en',
  'ph-tl': 'fp_PH',
  'pk-en': 'en',
  'pk-ur': 'ur',
  pl: 'pl_PL',
  'ps-ar': 'ar',
  'ps-en': 'en',
  'pt-en': 'en',
  'pt-pt': 'pt_PT',
  pw: 'en',
  py: 'es_LA',
  'qa-ar': 'ar',
  'qa-en': 'en',
  'ro-en': 'en',
  'ro-ro': 'ro_RO',
  'rs-en': 'en',
  'rs-sr': 'sr_RS',
  rw: 'en',
  'sa-ar': 'ar',
  'sa-en': 'en',
  sb: 'en',
  'sc-en': 'en',
  'sc-fr': 'fr_FR',
  se: 'sv_SE',
  'sg-en': 'en',
  'sg-zh': 'zh_SG',
  'si-en': 'en',
  'si-sl': 'sl_SI',
  'sk-en': 'en',
  'sk-sk': 'sk_SK',
  sl: 'en',
  'sm-en': 'en',
  'sm-it': 'it_IT',
  'sn-en': 'en',
  'sn-fr': 'fr_FR',
  'sr-en': 'en',
  'sr-nl': 'nl_NL',
  'st-en': 'en',
  'st-pt': 'pt_PT',
  sv: 'es_LA',
  sz: 'en',
  'td-en': 'en',
  'td-fr': 'fr_FR',
  'tg-en': 'en',
  'tg-fr': 'fr_FR',
  'th-en': 'en',
  'th-th': 'th_TH',
  'tj-en': 'en',
  'tj-ru': 'ru_RU',
  'tl-en': 'en',
  'tl-pt': 'pt',
  'tn-ar': 'ar',
  'tn-fr': 'fr_FR',
  to: 'en',
  'tr-en': 'en',
  'tr-tr': 'tr_TR',
  tt: 'en',
  tv: 'en',
  tw: 'zh_HANT',
  'tz-en': 'en',
  'tz-sw': 'sw',
  'ua-en': 'en',
  'ua-uk': 'uk_UA',
  'ug-en': 'en',
  'ug-sw': 'sw',
  uk: 'en_GB',
  us: 'en',
  uy: 'es_LA',
  uz: 'en',
  vc: 'en',
  've-en': 'en',
  've-es': 'es_LA',
  'vn-en': 'en',
  'vn-vi': 'vi_VN',
  'vu-en': 'en',
  'vu-fr': 'fr_FR',
  ws: 'en',
  'xk-en': 'en',
  'xk-sr': 'sr_RS',
  'za-en': 'en',
  'za-zu': 'zu_ZA',
  zm: 'en',
  zw: 'en',
};
