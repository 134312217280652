import marketLocales, {
  LanguageCode,
  Locale,
  MarketLocale,
} from 'lib/marketLocales';
import { PLAN_DUO, PlanType } from 'lib/plans';
import defaultTranslations from 'locales/originals/strings.json';
import { TranslationParameter } from 'lib/translationKeys';
import { getEnvironment } from 'lib/environment';
import { localeLanguageMapping } from 'lib/localeLanguageMapping';
import { interpolate } from 'lib/interpolate';

export type { Locale } from 'lib/marketLocales';

export type Translations = Record<string, any>;

export const defaultLocale: Locale = 'en';

/**
 * @deprecated use translation context instead
 */
let _locale: Locale;

export const defaultTranslationLoader = async (
  locale: Locale,
): Promise<Translations> => {
  const languageCode: LanguageCode = localeLanguageMapping[locale] || 'en';
  return import(
    /* webpackChunkName: "i18n-[request]" */
    `../locales/${languageCode}/strings.json`
  ).then(t => t.default);
};

// these can be used without having to be passed in as params
export const globalParamKeys = [
  'duo',
  'family',
  'kids',
  'kids_app',
  'spotify_kids',
] as const;

export const computeGlobalParams = (
  translations: Translations,
): Record<(typeof globalParamKeys)[number], string> => {
  return {
    duo: translations.duo,
    family: translations.family,
    kids: translations.kids,
    kids_app: translations.kids_app,
    spotify_kids: translations.spotify_kids,
  };
};

export const getDefaultTranslations = (): Translations => defaultTranslations;

// translations = {
//   "key1": "trans1",
//   "key2": "trans2 $(param1)"
// };
export const getTranslations = async (
  localeCode: Locale,
  localeLoader = defaultTranslationLoader,
): Promise<Translations> => {
  // Keep the original locale as we use it for links to the website
  _locale = localeCode;
  if (localeCode !== defaultLocale) {
    // Try to load the requested locale and fallback to default if not possible;
    try {
      return await localeLoader(localeCode);
    } catch (e) {
      // fallback to load default
    }
  }
  return getDefaultTranslations();
};

export const setLocaleSync = (localeCode: Locale) => {
  _locale = localeCode;
};

export const getLocaleFromUrl = (): Locale => {
  // Some modules evaluate `getLocale` on import (e.g. api.ts)
  // Because of that, we need to return a default locale when window is not defined.
  if (typeof window === 'undefined') return 'en';
  return window.location.pathname.split('/')[1] as Locale;
};

/**
 *  @deprecated Use useTranslation instead
 */
export const getLocale = (): Locale => _locale || getLocaleFromUrl();

// "hello ${world}"

type GlobalKeys = (typeof globalParamKeys)[number];
type OptionalGlobalStringParameters = { [key in GlobalKeys]?: string };

export type Translator = <K extends keyof TranslationParameter>(
  ...args: {} extends Record<
    Exclude<TranslationParameter[K]['parameters'][number], GlobalKeys>,
    string
  > &
    OptionalGlobalStringParameters
    ? [
        translationKey: K,
        localParams?: Record<
          Exclude<TranslationParameter[K]['parameters'][number], GlobalKeys>,
          string
        > &
          OptionalGlobalStringParameters,
      ]
    : [
        translationKey: K,
        localParams: Record<
          Exclude<TranslationParameter[K]['parameters'][number], GlobalKeys>,
          string
        > &
          OptionalGlobalStringParameters,
      ]
) => string;

type OptionalGlobalJSXParameters = { [key in GlobalKeys]?: string };

export type Interpolator = <K extends keyof TranslationParameter>(
  ...args: {} extends Record<
    Exclude<TranslationParameter[K]['parameters'][number], GlobalKeys>,
    string
  > &
    OptionalGlobalJSXParameters
    ? [
        translationKey: K,
        localParams?: Record<
          Exclude<TranslationParameter[K]['parameters'][number], GlobalKeys>,
          JSX.Element
        > &
          OptionalGlobalJSXParameters,
      ]
    : [
        translationKey: K,
        localParams: Record<
          Exclude<TranslationParameter[K]['parameters'][number], GlobalKeys>,
          JSX.Element
        > &
          OptionalGlobalJSXParameters,
      ]
) => string;

type MissingTranslationLogger = (translationKey: string) => void;
export const getTranslationFunction =
  (
    getDictionary: () => Translations,
    originals: Translations,
    logger?: MissingTranslationLogger,
  ) =>
  <K extends keyof TranslationParameter>(
    translationKey: K,
    localParams?: Record<
      Exclude<TranslationParameter[K]['parameters'][number], GlobalKeys>,
      string
    > &
      OptionalGlobalStringParameters,
  ) => {
    const translations = getDictionary();
    const globalParams = computeGlobalParams(translations);

    const params = { ...globalParams, ...localParams };

    if (!translations[translationKey]) {
      if (logger && getEnvironment() === 'production') {
        logger(translationKey);
      }
      return Object.keys(params).reduce((trans: string, paramKey: string) => {
        // @ts-ignore
        return trans.split(`$(${paramKey})`).join(params[paramKey]);
      }, originals[translationKey]);
    }

    return Object.keys(params).reduce((trans: string, paramKey: string) => {
      // @ts-ignore
      return trans.split(`$(${paramKey})`).join(params[paramKey]);
    }, translations[translationKey]);
  };

export const getInterpolationFunction =
  (
    getDictionary: () => Translations,
    originals: Translations,
    logger?: MissingTranslationLogger,
  ) =>
  <K extends keyof TranslationParameter>(
    translationKey: K,
    localParams?: Record<
      Exclude<TranslationParameter[K]['parameters'][number], GlobalKeys>,
      JSX.Element
    > &
      OptionalGlobalStringParameters,
  ) => {
    const translations = getDictionary();
    const globalParams = computeGlobalParams(translations);
    const params = { ...globalParams, ...localParams };

    if (!translations[translationKey]) {
      if (logger && getEnvironment() === 'production') {
        logger(translationKey);
      }
      // eslint-disable-next-line no-console
      return interpolate(originals[translationKey], params);
    }

    return interpolate(translations[translationKey], params);
  };

/**
 * t => string:key => object:params => string
 *
 * @deprecated Use useTranslation instead
 */

export function getLanguageFallbacks(
  selectedLocale: Locale,
  marketLocalesList = marketLocales,
): Array<LanguageCode> {
  if (!(selectedLocale in marketLocalesList)) {
    return ['en'];
  }
  return [...marketLocalesList[selectedLocale].languages, 'en'];
}

export function getPreferredLanguage(
  selectedLocale: Locale,
  allowList: Set<string>,
): LanguageCode {
  const fallbacks = getLanguageFallbacks(selectedLocale);
  for (const fallback of fallbacks) {
    if (allowList.has(fallback)) {
      return fallback;
    }
  }
  return 'en';
}

export const getPlanName = (
  planType: PlanType,
  translate: Translator,
): string => {
  return planType === PLAN_DUO ? translate('duo') : translate('family');
};

export const getLongPlanName = (
  planType: PlanType,
  translate: Translator,
): string => {
  return planType === PLAN_DUO
    ? translate('spotify_duo')
    : translate('spotify_family');
};

export const isRTLLanguage = (
  selectedLocale: Locale,
  marketLocalesList = marketLocales,
): boolean => {
  // use false as default for original translations
  if (!marketLocalesList[selectedLocale]) {
    return false;
  }
  return marketLocalesList[selectedLocale].dir === 'tb-rl';
};

export const isArabicLocale = (
  selectedLocale: Locale = getLocale(),
): boolean => {
  return !!(
    typeof selectedLocale === 'string' && selectedLocale.endsWith('-ar')
  );
};

export const getMarketLocaleInfo = (
  localeCode: Locale,
  marketLocalesList = marketLocales,
): MarketLocale | undefined => marketLocalesList[localeCode];

export const getMarketLocales = () => marketLocales;

export const getMainLanguage = (localeCode: Locale) =>
  getMarketLocaleInfo(localeCode)?.mainLanguage ?? 'en';

export const getDirectionality = (localeCode: Locale) =>
  isRTLLanguage(localeCode) ? 'rtl' : 'ltr';
