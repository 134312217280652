import React, { ReactNode } from 'react';
import { DuoTheme, FamilyTheme } from 'lib/theme';
import { PlanConsumer, PlanProvider } from 'data/plan/context';
import { ThemeProvider } from 'styled-components';

type DuoProviderProps = {
  children: ReactNode;
};

export const DuoProvider = ({ children }: DuoProviderProps) => {
  return (
    <ThemeProvider theme={DuoTheme}>
      <PlanProvider
        value={{
          planType: 'duo',
        }}
      >
        {children}
      </PlanProvider>
    </ThemeProvider>
  );
};

type FamilyProviderProps = {
  children: ReactNode;
};

export const FamilyProvider = ({ children }: FamilyProviderProps) => {
  return (
    <ThemeProvider theme={FamilyTheme}>
      <PlanProvider
        value={{
          planType: 'family',
        }}
      >
        {children}
      </PlanProvider>
    </ThemeProvider>
  );
};

export const withPlan = (Child: any) => (props: any) =>
  <PlanConsumer>{context => <Child {...context} {...props} />}</PlanConsumer>;

export default withPlan;
