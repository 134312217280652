import React from 'react';

/**
 * Usage
 * @param {sting}  text - text with placeholders to interpolate
 * @param {object} params - object containing translation keys and corresponding
 *                 components to replace the placeholders with
 * Idea from: https://github.com/facebook/react/issues/3386
 */
export const interpolate = (
  text: any,
  params: Record<string, string | JSX.Element> = {},
) => {
  const translationKeyPattern = /(\$\([a-z_0-9]+\))/g;
  const matches = text.match(translationKeyPattern);
  if (!matches) {
    return text;
  }

  const parts = text.split(translationKeyPattern);
  const wrappedNameParams = Object.fromEntries(
    Object.entries(params).map(([name, val]) => [`$(${name})`, val]),
  );
  return parts.map((part: any, index: any) =>
    wrappedNameParams[part] ? (
      <React.Fragment key={part + index}>
        {wrappedNameParams[part]}
      </React.Fragment>
    ) : (
      part
    ),
  );
};
